// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import escapeHTML from 'escape-html';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
import tag from 'html5-tag';
import iconsvg  from './icons.js';

export default (node, treeOptions) => {
  const {
    // eslint-disable-next-line no-unused-vars
    id, name, сheckAllowed, viewAllowed, children, state
  } = node;

  const {
    depth, open, path, total, selected = false, filtered // , checked, indeterminate
  } = state;
  const childrenLength = Object.keys(children).length;

  if (filtered === false) {
    return;
  }

  const more = node.hasChildren();

  const togglerContent = '';

  let i = '';
  let button = '';
  if (viewAllowed) {
    i = tag('i', {
      class: 'el-icon-search'
    }, '');
    button = tag('button', {
      type: 'button',
      class: 'el-button el-button--text'
    }, i);
  }

  let checkbox = '';
  if (сheckAllowed) {
    checkbox = tag('input', {
      type: 'checkbox',
      checked: node.state.checked,
      disabled: node.disabled,
      class: 'el-checkbox',
      'data-indeterminate': node.state.indeterminate
    });
  }

  const toggler = tag('div', {
    class: (() => {
      if (more && open) {
        return classNames(treeOptions.togglerClass);
      }
      if (more && !open) {
        return classNames(treeOptions.togglerClass, 'infinite-tree-closed');
      }
      return '';
    })()
  }, togglerContent);

  const icon = iconsvg(node);

  const title = tag('span', {
    class: ''
  }, escapeHTML(name));

  const treeNode = tag('div', {
    class: 'infinite-tree-node',
    style: `margin-left: ${(depth) * 15}px; `
  }, button + checkbox + toggler + icon + title);

  // eslint-disable-next-line consistent-return
  return tag('div', {
    'data-id': id,
    'data-expanded': more && open,
    'data-depth': depth,
    'data-path': path,
    'data-selected': selected,
    'data-children': childrenLength,
    'data-total': total,
    class: '',
  }, treeNode);
};
