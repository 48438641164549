<template>
  <div ref="treeContainer"/>
</template>

<script>
import InfiniteTree from 'infinite-tree';
import renderer from './renderer';

const lcfirst = (str) => {
  str += '';
  return str.charAt(0).toLowerCase() + str.substr(1);
};
export default {
  name: 'TreeContainer',
  components: {

  },
  inheritAttrs: false,
  props: {
    className: {
      type: String,
      default: 'scroll-box'
    },
    autoOpen: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: null // 'Осуществляется загрузка дерева...'
    },
    tabIndex: {
      type: Number,
      default: 1
    },
    data: {
      type: [Array, Object],
      default: () => []
    },
    rowHeight: {
      type: Number,
      default: 32
    },
    /* loadNodes: {
        type: Function,
        default: () => {}
    }, */
    shouldSelectNode: {
      type: Function,
      default: () => {}
    },
    shouldLoadNodes: {
      type: Function,
      default: () => {}
    },
    onClusterDidChange: {
      type: Function,
      default: null
    },
    // Callback invoked before updating the tree.
    onContentWillUpdate: {
      type: Function,
      default: null
    },
    // Callback invoked when the tree is updated.
    onContentDidUpdate: {
      type: Function,
      default: null
    },
    // Callback invoked when a node is opened.
    onOpenNode: {
      type: Function,
      default: null
    },
    // Callback invoked when a node is closed.
    onCloseNode: {
      type: Function,
      default: null
    },
    // Callback invoked when a node is selected or deselected.
    onSelectNode: {
      type: Function,
      default: null
    },
    // Callback invoked before opening a node.
    onWillOpenNode: {
      type: Function,
      default: null
    },
    // Callback invoked before closing a node.
    onWillCloseNode: {
      type: Function,
      default: null
    },
    // Callback invoked before selecting or deselecting a node.
    onWillSelectNode: {
      type: Function,
      default: null
    },
    onKeyUp: {
      type: Function,
      default: null
    },
    onKeyDown: {
      type: Function,
      default: null
    },
    onMouseLeave: {
      type: Function,
      default: null
    },
    onMouseEnter: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      nodes: [],
      tree: {
        nodes: []
      },
      eventHandlers: {
        onClusterDidChange: null,
        onContentWillUpdate: null,
        onContentDidUpdate: null,
        onOpenNode: null,
        onCloseNode: null,
        onSelectNode: null,
        onWillOpenNode: null,
        onWillCloseNode: null,
        onWillSelectNode: null,
        onKeyUp: null,
        onKeyDown: null,
        onMouseEnter: null,
        onMouseLeave: null
      }
    };
  },
  computed: {},
  watch: {
    filter: {
      handler(newValue) {
        this.handleFilter(newValue);
      },
    }
  },
  mounted() {
    // const container = document.querySelector('#inf-tree-container');
    this.tree = new InfiniteTree({
      el: this.$refs.treeContainer,
      rowRenderer: renderer,
      ...this.$props
    });

    Object.keys(this.eventHandlers).forEach((key) => {
      if (!this[key]) {
        return;
      }
      const eventName = lcfirst(key.substr(2)); // e.g. onContentWillUpdate -> contentWillUpdate
      this.eventHandlers[key] = this[key];
      this.tree.on(eventName, this.eventHandlers[key]);
    });

    // обработка события на просмотр (handleView)
    this.tree.on('click', (event) => {
      // eslint-disable-next-line no-restricted-globals
      const currentNode = this.tree.getNodeFromPoint(event.clientX, event.clientY);
      if (!currentNode) {
        return;
      }

      // console.log(event.target);

      if (event.target.className === 'el-icon-search') {
        event.stopPropagation();
        this.$emit('on-view-click', currentNode);
      } else if (event.target.className === 'el-checkbox') {
        event.stopPropagation();
        this.checkSingleNode(currentNode);
        this.$emit('on-check-click', currentNode);
      } else {
        event.stopPropagation();
        this.tree.toggleNode(currentNode);
      }
    });
  },
  beforeDestroy() {
    Object.keys(this.eventHandlers).forEach((key) => {
      if (!this.eventHandlers[key]) {
        return;
      }
      const eventName = lcfirst(key.substr(2)); // e.g. onUpdate -> update
      this.tree.removeListener(eventName, this.eventHandlers[key]);
      this.eventHandlers[key] = null;
    });
    this.tree.destroy();
    this.tree = null;
  },
  methods: {
    checkSingleNode(node, checked) {
      // Retrieve node index
      const nodeIndex = this.tree.nodes.indexOf(node);
      if (nodeIndex < 0) {
        console.warn('Invalid node index');
        return false;
      }

      if (checked === true) {
        node.state.checked = true;
        node.state.indeterminate = false;
      } else if (checked === false) {
        node.state.checked = false;
        node.state.indeterminate = false;
      } else {
        node.state.checked = !!node.state.checked;
        node.state.indeterminate = !!node.state.indeterminate;
        node.state.checked = (node.state.checked && node.state.indeterminate) || (!node.state.checked);
        node.state.indeterminate = false;
      }

      this.tree.updateNode(node);

      return true;
    },
    toggleNodes() {
      this.$parent.toggleAllNodes();
    }
  }
};
</script>

<style>
.infinite-tree-scroll {
  width: auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.infinite-tree-table {
  width: 100%;
}
.infinite-tree-content {
  outline: 0;
  position: relative;
}
.infinite-tree-content .infinite-tree-selected.infinite-tree-item,
.infinite-tree-content .infinite-tree-selected.infinite-tree-item:hover {
  background: #deecfd;
  /* border: 1px solid #06c; */
}
.infinite-tree-content .infinite-tree-item {
  /* border: 1px solid transparent; */
  cursor: default;
}
.infinite-tree-content .infinite-tree-item:hover {
  background: #f2fdff;
}
.infinite-tree-content .infinite-tree-item:disabled,
.infinite-tree-content .infinite-tree-item[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.infinite-tree-content .infinite-tree-node {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
}
.infinite-tree-content .infinite-tree-toggler {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.infinite-tree-content .infinite-tree-toggler:hover {
  text-decoration: none;
}
.infinite-tree-content .infinite-tree-title {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.infinite-tree-no-data {
  text-align: center;
}
</style>
